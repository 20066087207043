@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Regular.woff2') format('woff2'),
    url('fonts/OpenSauceSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Italic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans ExBold';
  src: url('fonts/OpenSauceSans-ExtraBoldItalic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Bold.woff2') format('woff2'),
    url('fonts/OpenSauceSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-SemiBoldItalic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-SemiBold.woff2') format('woff2'),
    url('fonts/OpenSauceSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Medium.woff2') format('woff2'),
    url('fonts/OpenSauceSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-BlackItalic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-LightItalic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Black.woff2') format('woff2'),
    url('fonts/OpenSauceSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Light.woff2') format('woff2'),
    url('fonts/OpenSauceSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-MediumItalic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-ExtraBold.woff2') format('woff2'),
    url('fonts/OpenSauceSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-BoldItalic.woff2') format('woff2'),
    url('fonts/OpenSauceSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Open Sauce Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* Aspect ratio of 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.outer-div {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  /* For Firefox */
}

.outer-div::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  /* Adjusted width of the scrollbar */
}

.outer-div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* Adjusted border-radius for a thinner look */
  background-color: rgba(0, 0, 0, 0.5);
  /* Color and opacity of the scrollbar */
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  /* Shadow for scrollbar */
}

@keyframes dotAnimation {
  0% { content: ""; }
  25% { content: "."; }
  50% { content: ".."; }
  75% { content: "..."; }
  100% { content: ""; }
}

.dots::after {
  color: rgb(2 132 199);
  display: inline-block;
  width: 40px;
  content: "";  /* Default content */
  animation: dotAnimation 2s infinite;
}
